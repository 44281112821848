import React from 'react';
import tenantConfigs from './tenantConfig.json';
import Tenants from './Tenants.json'


export default function Tenant (){
    const tenantID: string = getTenantID();
    return tenantConfigs.filter(e => e.tenantID === tenantID)[0];
    
}

function getTenantID(){
    const host: string = window.location.hostname;
    //return host;
    return Tenants.filter(e => e.webURL === host)[0].tenantID;
}
