import React from 'react';
import logo from './logo.svg';
import './App.css';

import { ChakraProvider, Container } from '@chakra-ui/react'
import Footer from './components/footer/footer';
import Navbar from './components/header/navbar';
import CaptionCarousel from './components/home/CaptionCarousel';
import SimpleThreeColumns from './components/features/SimpleThreeColumns';
import { INav } from './interfaces/siteConfig';
import Tenant from './config/tenantConfig/Tenant';

let navPrimary: INav;
//navPrimary = tenantConfig[0].navFooterParimary;

navPrimary = Tenant().navFooterParimary;

function App() {
  return (
    <ChakraProvider>
      <Container maxW='1150px'>
        <Navbar />
      </Container>
      <CaptionCarousel />
      <Container maxW='1150px'>
        <SimpleThreeColumns />
      </Container>
      
      <Footer navPrimary={navPrimary}  />
    </ChakraProvider>
  );
}

export default App;
